import React, { useState, useContext } from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { useHomePage, useWebsiteConfiguration } from "~queries/hooks"
import classnames from "classnames"

/* Import Global Context(s) */
import LocationContext from "~context/currentLocation"

const NavigationColumns = ({ pathname }) => {

	const [currentLocation, setCurrentLocation] = useContext(LocationContext)

	const { 
		displayProgramEvents, 
		displayTickets, 
		displayFairGuide, 
		fairGuideButton, 
		displayVirtual, 
		displayHotel, 
		hotelButton,  // used for color highlight
		displayEdits 
	} = useHomePage()

	const { activeLocation, menuLabelMiami, menuLabelHouston } = useWebsiteConfiguration()

	const setMiami = () => {
		setCurrentLocation({activeLocation: 'miamiBeach', hasBeenSet: true})
	}

	const setHouston = () => {
		setCurrentLocation({activeLocation: 'houston', hasBeenSet: true})
	}

return (<section className="nav_columns font-size--small">
	
		<aside className="col-3-13 mcol-6-6 morder-1">
			
			{!currentLocation.hasBeenSet && (<p>
				{activeLocation == 'houston' && (<><button className="menu_link" onClick={setHouston}>Houston</button><br /></>)}
				<button className="menu_link" onClick={setMiami}>Miami Beach</button>
				{activeLocation != 'houston' && (<><br /><button className="menu_link" onClick={setHouston}>Houston</button></>)}
			</p>)}

			{ currentLocation.hasBeenSet && currentLocation.activeLocation == 'miamiBeach' && menuLabelMiami && menuLabelMiami.map((a, i) => (<p 
					key={'animation_'+i}
					className={classnames({'dark_gray': i + 1 == menuLabelMiami.length})}
				>{a}</p>))}
			{ currentLocation.hasBeenSet && currentLocation.activeLocation == 'houston' && menuLabelHouston && menuLabelHouston.map((a, i) => (<p 
					key={'animation_'+i}
					className={classnames({'dark_gray': i + 1 == menuLabelHouston.length})}
				>{a}</p>))}
			{currentLocation.hasBeenSet && (<p>
				{currentLocation.activeLocation == 'houston' && (<><button className="menu_link" onClick={setMiami}>Miami Beach</button></>)}
				{currentLocation.activeLocation == 'miamiBeach' && (<><button className="menu_link" onClick={setHouston}>Houston</button></>)}
			</p>)}
		</aside>

		<ul className="link_dots col-3-13 offset-1-2 moffset-0 mcol-3-6 morder-3">
			{displayFairGuide && <li className="link_dot"><Link to="/fair-guide">Fair Guide</Link></li>}
			{displayProgramEvents && <li className="link_dot"><Link to="/program">Program</Link></li>}
			{displayVirtual && <li className="whitespace link_dot"><Link to="/virtual-fair">Virtual Fair</Link></li>}

		</ul>
		<ul className="col-2-13 moffset-0 mcol-3-6 morder-2">
			<li className="menu_link"><Link to="/about">About</Link></li>
			<li className="menu_link"><Link to="/visit">Visit</Link></li>
			{displayHotel && <li className="menu_link"><Link to="/travel">Travel</Link></li>}
		</ul>
		<ul className="col-2-13 offset-1 moffset-0 mcol-3-6 morder-4 moffset-y-1-2">
			<li className="menu_link"><Link to="/galleries">Galleries</Link></li>
			<li className="menu_link"><Link to="/partners">Partners</Link></li>
			{displayTickets && <li className="menu_link whitespace"><Link to="/tickets">Buy Tickets</Link></li>}
		</ul>
		<ul className="col-3-13 mcol-6-6 morder-5 moffset-y-1-2">
			{displayEdits && <li className="menu_link"><Link to="/edits">Untitled Edit</Link></li>}
			<li className="menu_link"><Link to="/news">News</Link></li>
			<li className="menu_link"><Link to="/podcast">Podcast</Link></li>
		</ul>

</section>)

}

export default NavigationColumns